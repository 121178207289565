<template>
  <v-row>
    <v-col cols="12" sm="6" md="4">
      <div class="d-flex">
        <SelectLanguageFromISO @changeLanguage="onChangeLanguage" ref="language" />
        <v-btn @click="addLanguage" :disabled="isAddButtonDisabled" icon color="success" class="ml-1">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn @click="deleteLanguage" :disabled="isDeleteButtonDisabled" icon color="error" class="ml-1">
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
      </div>
    </v-col>
    <v-col cols="12" sm="6" md="8">
      <v-radio-group
        v-model="currentFormLanguage"
        @change="$emit('changeFormLanguage', currentFormLanguage)"
        class="mt-1"
        row
        hide-details
      >
        <v-radio
          v-for="(language, index) in formLanguages"
          :key="index"
          :label="language.nativeName"
          :value="language.code"
        ></v-radio>
      </v-radio-group>
    </v-col>
  </v-row>
</template>

<script>
import GetLanguagesFromISO from '@/mixins/getLanguagesFromISO-639-1'

export default {
  name: 'SetFormLanguage',

  components: {
    SelectLanguageFromISO: () => import('@/components/partials/SelectLanguageFromISO.vue'),
  },

  mixins: [GetLanguagesFromISO],

  props: {
    isEditable: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    defaultLanguage: {
      code: 'uk',
      name: 'Ukrainian',
      nativeName: 'Українська',
    },
    selectedLanguage: {},
    currentFormLanguage: '',
    formLanguages: [],
  }),

  computed: {
    isAddButtonDisabled() {
      return !this.selectedLanguage || this.formLanguages.includes(this.selectedLanguage) || this.selectedLanguage.code === 'uk'
    },
    isDeleteButtonDisabled() {
      return !this.formLanguages.includes(this.selectedLanguage) || this.selectedLanguage.code === 'uk'
    },
  },

  created() {
    this.initialize()
  },

  mounted() {
    if (!this.isEditable) this.addLanguage()
  },

  methods: {
    initialize() {
      this.selectedLanguage = { ...this.defaultLanguage }
      this.currentFormLanguage = 'uk'
      if (this.isEditable) {
        this.getAllLanguages()
        this.getAllAvailableFilteredLanguages()
        this.formLanguages = [...this.filteredLanguages]
      }
    },
    onChangeLanguage(lang) {
      this.selectedLanguage = lang
    },
    addLanguage() {
      this.formLanguages.push(this.selectedLanguage)
    },
    deleteLanguage() {
      this.formLanguages.splice(this.formLanguages.indexOf(this.selectedLanguage), 1)
      this.$emit('deleteLanguage', this.selectedLanguage.code)
      this.initialize()
      this.$refs.language.clear()
    },
  },
}
</script>
